import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoPageTemplate = ({ data, pageContext, location }) => {
  const page = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: `var(--maxWidth-4xl)`,
          padding: `var(--spacing-12) var(--spacing-4)`
        }}
      >
      <SEO
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />
      <article>
        <header>
          <h1
            style={{
              marginBottom: `var(--spacing-4)`,
              marginTop: `var(--spacing-4)`,
              textAlign: 'center'
            }}
          >
            {page.frontmatter.title}
          </h1>
        </header>
        <section dangerouslySetInnerHTML={{ __html: page.html }} />
        <hr
          style={{
            marginBottom: `var(--spacing-4)`,
          }}
         />

      </article>
    </div>
    </Layout>
  )
}

export default InfoPageTemplate

export const pageQuery = graphql`
  query InfoPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`
